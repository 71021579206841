
import React, { useContext } from "react";
import Profile from '../views/Profile';
import { UserContext } from '../../../Context/UserContext/UserContext';


const ProfileContainer = ({ location }) => {

  const { userGlobalState } = useContext(UserContext);
  
  return (
    <Profile 
        userGlobalState={userGlobalState}
    />
  );
};

export default ProfileContainer;
