
import React, { useContext } from "react";
import { UserContext } from "../Context/UserContext/UserContext";


const DashboardWrapper = ({ children, location }) => {

  const { userGlobalState } = useContext(UserContext);

  if(userGlobalState) {
    if(userGlobalState.isValid === false) {
      return 'You session expired, please login';
    }
    else if(userGlobalState.artist) {
      return children;
    }
    else {
      return 'Loading...';
    }
  }

  else {
    return 'Loading...';
  }

};

export default DashboardWrapper;
