import React from 'react';
import { Link } from 'gatsby';
import Navbar from '../../../lib/components/Navbar/Navbar';
import CenterContainer from '../../../lib/components/CenterContainer/CenterContainer';
import styles from './Profile.module.css';
import Button from '../../../lib/components/Button/Button';
import DashboardWrapper from '../../DashboardWrapper';
import { Helmet } from "react-helmet"

const Profile = ({ userGlobalState }) => {
  const { artist } = userGlobalState || {};

  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <Navbar />
      <CenterContainer>
        <DashboardWrapper>
          {userGlobalState && userGlobalState.artist ? (
            <div className={styles.profile__container}>
              <div
                style={{
                  backgroundImage: `url("${
                    artist.avatarImgUrl || '/images/placeholderImg.jpg'
                  }"`
                }}
                className={styles.profile__img}
              />
              <div className={styles.profile__infoContainer}>
                <h1 style={{ margin: '0 0 15px 0' }}>{artist.name}</h1>
                <p style={{ maxWidth: '400px' }}>
                  {artist.bio || <i>no bio available yet</i>}
                </p>
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '8px',
                    color: '#c4c4c4',
                    marginBottom: '15px'
                  }}>
                  You are logged in with the Apple ID: {artist.appleId}
                </p>
                <Link to={'/dashboard/profile/edit'}>
                  <Button>Edit</Button>
                </Link>
              </div>
            </div>
          ) : (
            'Loading...'
          )}
        </DashboardWrapper>
      </CenterContainer>
      <br />
      <br />
      <br />
    </>
  );
};

export default Profile;
