
import React from "react";
import ProfileContainer from '../../../Dashboard/profile/containers/ProfileContainer';


const ProfilePage = ({ location }) => {

  return (
    <ProfileContainer location={location}/>
  )
}

export default ProfilePage;
